<template>
    <div class="pl-invoice-wrap">
        <div class="module-title">发票列表</div>
        <div class="pl-search-box pl-flex">
            <div class="pl-left">
                <span>开票状态</span>
                <el-select v-model="param.status" @change="invoiceOrderList">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="pl-right">
                <el-button type="primary" @click="$router.push('/user/invoice/make')">申请开票</el-button>
                <el-button type="primary" @click="editeInvoice">修改开票信息</el-button>
            </div>
        </div>
        <p class="pl-invoice-info show">有<span class="color">{{ info.unbilled_order_total || 0
        }}</span>个订单可申请开发票,可开票总金额<span class="color">￥{{ info.unbilled_order_amount || 0 }}</span></p>

        <el-table :data="tableData" border>
            <el-table-column width="200" align="center" header-align="center" prop="sn" label="发票单号">
            </el-table-column>
            <el-table-column width="200" align="center" header-align="center" prop="created_at" label="申请时间">

            </el-table-column>
            <el-table-column align="center" header-align="center" prop="type" label="类型">
                <template slot-scope="scope">
                    {{ scope.row.business_type == 1 ? '专票' : '普票' }}
                </template>
            </el-table-column>
            <el-table-column width="200" align="center" header-align="center" prop="header" label="发票抬头">
            </el-table-column>
            <el-table-column align="center" header-align="center" label="开票金额" prop="total_amount"></el-table-column>
            <!-- <el-table-column align="center" header-align="center" label="性质" prop="sz"></el-table-column> -->
            <el-table-column align="center" header-align="center" label="状态">
                <template slot-scope="scope">
                    <span>{{ invoiceStatus[scope.row.status] }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="pl-pagination">
            <el-pagination background layout="prev, pager, next" :total="param.total" @current-change="changePage">
            </el-pagination>
        </div>

        <!-- 开票信息 -->
        <InvoiceaEdit :invoiceaData="invoiceaData" @hideInvoiceaEdit="isShowInvoiceaEdit = false"
            v-if="isShowInvoiceaEdit" />
    </div>
</template>
<script>
import { invoiceOrderList, invoiceAmount, getInvoiceInfo } from '@/api/invoice'
import InvoiceaEdit from '@/components/user/invoicea-edit.vue'
export default {
    components: {
        InvoiceaEdit
    },
    data() {
        return {
            options: [
                { label: '全部', value: '' },
                { label: '开票中', value: 0 },
                { label: '已开票', value: 1 },
                { label: '已拒绝', value: 2 },
            ],
            invoiceStatus: ['开票中', '已开票', '已拒绝'],
            tableData: [],
            param: {
                page: 1,
                per_page: 10,
                total: 0,
                status: ''
            },
            info: {},
            isShowInvoiceaEdit: false,
            invoiceaData: {}
        }
    },
    methods: {
        invoiceOrderList() {
            invoiceOrderList(this.param).then(res => {
                let { total, data } = res
                this.param.total = total
                this.tableData = data || []
            })
        },
        changePage(v) {
            this.param.page = v
            this.invoiceOrderList()
        },
        editeInvoice() {
            getInvoiceInfo().then(res => {
                this.invoiceaData = res || {}
                this.isShowInvoiceaEdit = true
            })
        }
    },
    created() {
        invoiceAmount().then(res => {
            this.info = res || {}
        })
        this.invoiceOrderList()
    }
}
</script>
<style lang="scss" scoped>
.pl-invoice-wrap {
    .pl-search-box {
        margin: 15px 0;

        .pl-left {
            span {
                padding-right: 15px;
            }
        }

    }

    .color {
        color: #3385FF;
    }

    .pl-invoice-info {
        margin: 15px 0;
    }

    .show {
        display: none;
    }
}
</style>